/*
=========================================
=========================================

Item Name: Elisyam - Web App & Admin Dashboard Template
Version: 1.1
Author: SAEROX
Envato: https://www.themeforest.net/user/saerox?ref=Saerox

* =======================================
*     TABLE OF CONTENTS
* =======================================
01) Globals
02) Components
  02.01) Timeline
  02.02) Tabs
  02.03) Alerts
  02.04) Accordion
  02.05) Popover
  02.06) Tooltip
03) Layout
  03.01) Dashboard
  03.02) Header
  03.03) Sidebar
  03.04) Offcanvas Sidebar
  03.05) Widgets
  03.06) Footer
04) Applications
  04.01) Mail
  04.02) Calendar
  04.03) Contact
  04.04) Chat
05) Pages
  05.01) Authentication
  05.02) Coming Soon
  05.03) Error
  05.04) Pricing Tables
  05.05) Invoice
  05.06) Search Results
  05.07) Faq
  05.08) Newsfeed
  05.09) Friends
06) Responsive
=========================================
========================================= */
/* ========================================= */
/*     01) ELISYAM GLOBALS                   */
/* ========================================= */






html {
    font-size: 14px;
  }
  
  body,html {
    height: 100%;
  }
  
  body {
    font-family: "Montserrat", sans-serif;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;
    color: #98a8b4;
    background-color: #f2f3f8;
  }
  
  a {
    color: #5d5386;
    text-decoration: none;
    transition: all 0.5s ease;
  }
  
  
  body {
    overflow-x: hidden;
  }
 
 

  
  /* h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    margin-bottom: 0.5rem;
    font-family: "Noto Sans", sans-serif;
    line-height: 1.1;
    color: #2c304d;
  }
   */
  /* h1, .h1 {
    font-size: 2.5rem;
    font-weight: 600;
  }
  
  h2, .h2 {
    font-size: 1.5rem;
    font-weight: 500;
  }
  
  h3, .h3 {
    font-size: 1.35rem;
    font-weight: 500;
  }
  
  h4, .h4 {
    font-size: 1.2rem;
    font-weight: 500;
  }
  
  h5, .h5 {
    font-size: 1rem;
    font-weight: 500;
  }
  
  h6, .h6 {
    font-size: 0.85rem;
    font-weight: 500;
  }
  
  .lead {
    font-size: 1.25rem;
    font-weight: 300;
  } */
  
  
 
  
  
  /*
   * LOGO
   */
  .logo-centered {
    width: 200px;
    margin: 0 auto;
  }
  
  .logo-centered img {
    width: 200px;
    height: 200px;
    margin-bottom: 50px;
  }
  .PageHeader{
    text-align: left;
    font: normal normal bold 150%/22px Roboto;
    letter-spacing: 0px;
    color: #1E1E1E;
    opacity: 1;
    margin-bottom: 10%;
    font-size: 1.5rem;
  }
 

  .background{
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('../../assets/SwaggerBackground.png');
    background-color: #003182;
    background-repeat: no-repeat;
    background-size: cover;
    /* background-position: center; */
    object-fit: contain;
  }
  .background1{
    position: absolute;
    height: 80vh;
    width: 100%;
    background-image: url('../../assets/SwaggerBackground.png');
    /* background-color: #FFFFFF; */
    background-repeat: no-repeat;
    background-size: cover;
    /* background-position: center; */
    object-fit: contain;
    opacity: 0.65;
  }
  .container_content{
    height: 100%;
     width: 40%;
  }

  .content{
    position: relative;
    top: 23%;
  }
  
  .logoImg{
    position: relative;
    top: 5%;
  }
.logoImg img{
  height: 48px;
  width: 162px;
}

.WelcomeMsg h1{
  /* width: 339px;
  height: 77px; */
  color: #FFFFFF;
  /* text-align: left;
  letter-spacing: 0px;
  font-size: 80px;
  opacity: 1; */
  font: normal normal bold 55px/0px Roboto;
}
.CEP p{
  color: #FFFFFF;
  /* text-align: left;
  letter-spacing: 0px;
  opacity: 1; */
  font: normal normal normal 35px/100px Roboto;
  margin-bottom: 4rem;
}
.SL h1{
  color: #FFFFFF;
  text-align: left;
  letter-spacing: 0px;
  opacity: 1;
  font-size: 40px;
}

.LogoSign{
  margin-bottom: 3rem; 
}

.LogoSign img{
  height: 49px;
  width: 148px;
}

  .LogoutButton{
     position: absolute;
     top: 0%;
     right: 0%;
  }

  .LogoutButton button{
     border-radius: 25px;
     width: 125px;
  }
  
  .navbar1{
    display: flex;
    align-items: center;
    justify-content: space-around;
    /* border-bottom: 1px solid grey; */
    box-shadow: 0px 3px 6px #0000000D;
    opacity: 1;
    margin: 5px;
    background-color: #FFFFFF;
  }
  

  /*
   * BUTTON
   */

   .sign-btn button{
    background: #0047BA 0% 0% no-repeat padding-box;
    width: 60%;
    height: 4rem;
    opacity: 1;
    color: #FFFFFF;
    font-size: 20px;
    border-radius: 60px;
    border: 2px solid #0047BA;
    font-weight: bold;
   }
   .sign-btn1 button{
    background: #0047BA 0% 0% no-repeat padding-box;
    width: 15rem;
    height: 10rem;
    opacity: 1;
    color: #FFFFFF;
    font-size: 2rem;
    border-radius: 26px;
    font-weight: bold;
    padding: 30px;
    border: none;
   }

  .view-public-api button{
    background: #0047BA 0% 0% no-repeat padding-box;
    width: 60%;
    height: 4rem;
    opacity: 1;
    color: #FFFFFF;
    font-size: 20px;
    border-radius: 60px;
    border: 2px solid #0047BA;
    font-weight: bold;
  }

   .FooterClass1{
     /* height: 20%; */
     width: 100%;
     background: transparent linear-gradient(180deg, #27358B 0%, #30A5DE 100%) 0% 0% no-repeat padding-box;
     position: absolute;
     z-index: 999;
     bottom: 0;
   }

   .FooterClass{
    /* height: 218px; */
    width: 100%;
    background: transparent linear-gradient(180deg, #27358B 0%, #30A5DE 100%) 0% 0% no-repeat padding-box;
    /* position: absolute;
    z-index: 999;
    bottom: 0; */
  }


  
  /* .btn {
    font-weight: 600 !important;
    line-height: 1.25;
    border: 2px solid transparent;
    padding: 10px 20px;
    font-size: 0.85rem;
    border-radius: 60px;
    transition: all 0.2s ease-in-out;
    position: relative;
    overflow: hidden;
  }
   */
  /* .btn:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 5px;
    height: 5px;
    background: rgba(255, 255, 255, .2);
    opacity: 0;
    border-radius: 100%;
    transform: scale(1, 1) translate(-50%);
    transform-origin: 50% 50%;
  } */
  
  @keyframes ripple {
    0% {
      transform: scale(0, 0);
      opacity: 1;
    }
    20% {
      transform: scale(25, 25);
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: scale(40, 40);
    }
  }
  
/*   
  .btn-gradient-01, .btn-gradient-01 a {
    background: #e23f83;
    background: linear-gradient(to right, #e23f83 0%, #ea5844 100%);
    background-size: 200% auto;
    font-weight: 600;
    transition: 0.5s;
    color: #fff;
    border: 0 none;
    padding: 12px 20px;
  }
  
  .btn-gradient-01 i, .btn-gradient-01 a i {
    color: #fff;
    display: inline-block;
    font-size: 1.2rem;
    vertical-align: middle;
    margin-right: 0.4rem;
  }
  
  .btn-gradient-01:hover, .btn-gradient-01 a:hover {
    color: #fff;
    background-position: right center;
  }
  
   */
  
  .new-card{
    border: 1px solid #E1E1E1;
    padding: 10px;
    cursor: pointer;
    color: #000000;
    font-family: Roboto;
    width: 30%;
    margin-right: 3%;
    margin-bottom: 1%;
   }
/* 
   .footer-circle{
    height: 20px;
    width: 20px;
    background-color: #FFFFFF;
    border-radius: 50%;
  } */
 
  .page-header-title{
    font-family: Roboto;
    font-size: large;
    color: #000000;
  }

  .footer-line{
    font-size: small;
    font-weight: 100;
    margin-bottom: 0;
  }
  .Copyright{
    font-size: small;
    font-weight: 100;
    margin-bottom: 0.3%;
    text-align: center;

  }

  .footer-container{
    padding-top: 3%;
    
  }

  /* .app-name{
    margin-bottom: 0;
    margin-top: 2px
  } */

  .inline{
    display: inline-block;
  }

  .app-name{
    margin-bottom: 0;
    color: #000000;
    font-size: 1.3rem;
  }

  .app-description{
    margin-bottom: 5px;
    font-size: .9rem;
    font-weight: 400;
    color: #848484
  }

  .app-name-desc{
    margin-top: 20px;
  }

  .app-icon{
    margin: 20px;
    color: #0047BA;
  }

  .main-app-card{
    padding-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .listItems{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

   .profileName {
    padding: 0px 10px ;
  }

   .or-container{
     margin-top: 20px;
     margin-bottom: 10px;
     width: 60%;
     display: flex;
     flex-direction: row;
     flex-wrap: nowrap;
     justify-content: center;
     align-items: center;
   }

  .or-container > p{
    color: white;
  }

  
  
 
  
 
 
  
 
  
  

  
 
 
  
  
  
  
 
 

  
  
 
  
  
  
  
  
 
 







  
 
  
 
 
  
  
  
 
  
