.header {
    min-height: 70px;
    position: relative;
  }
  
  .navbar-header .logo-big {
    width: 170px;
  }
  
  .navbar-header .logo-small {
    width: 70px;
  }
  
  .navbar-holder {
      height: 75px;
    width: 100%;
  }
  
  nav.navbar {
    background: #fff;
    padding: 0 15px;
    color: #aea9c3;
    border-radius: 0;
    box-shadow: 0 1px 0 #eee;
    z-index: 1000;
    width: 100%;
  }
  
  nav.navbar .badge {
    display: block;
    width: 12px;
    height: 12px;
    line-height: 12px;
    text-align: center;
    padding: 0;
    border-radius: 50%;
  }
  
  nav.navbar .navbar-brand .brand-small {
    display: none;
  }
  
  nav.navbar a {
    color: inherit;
  }
  
  nav.navbar .container-fluid {
    width: 100%;
  }
  
  nav.navbar .menu-btn {
    margin-right: 20px;
    font-size: 1.2em;
    transition: all 0.7s;
  }
  
  nav.navbar .menu-btn span {
    background: #aea9c3;
    width: 20px;
    height: 1px;
    display: block;
    margin: 7px 0 0;
    transition: all 0.3s cubic-bezier(0.81, -0.33, 0.345, 1.375);
  }
  
  nav.navbar .menu-btn span:nth-of-type(2) {
    position: relative;
    width: 30px;
  }
  
  nav.navbar .menu-btn span:last-of-type {
    position: relative;
    width: 15px;
  }
  
  nav.navbar .menu-btn.active span:first-of-type {
    transform: translateX(10px);
  }
  
  nav.navbar .menu-btn.active span:nth-of-type(2) {
    transform: none;
  }
  
  nav.navbar .menu-btn.active span:last-of-type {
    transform: translateX(15px);
  }
  
  nav.navbar .nav-item > a {
    position: relative;
    font-size: 1.8rem;
    padding: 0;
    width: 50px;
    text-align: center;
    line-height: 70px;
  }
  
  nav.navbar .nav-item > a span {
    position: absolute;
    top: 20px;
    right: 10px;
    border: 3px solid #fff;
  }
  
  nav.navbar .nav-item > a.logout i {
    margin-left: 10px;
  }
  
  nav.navbar .search-box {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
    background: #fff;
    z-index: 9999;
    border-radius: 0;
    display: none;
  }
  
  nav.navbar .search-box .dismiss {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.2rem;
    color: #5d5386;
  }
  
  nav.navbar .search-box form {
    height: 100%;
  }
  
  nav.navbar .search-box form input {
    height: 100%;
    border: none;
    padding: 20px;
    color: #5d5386;
  }
  
  nav.navbar .search-box form input::placeholder {
    color: #2a2c3d;
    font-weight: 500;
  }

  .dropbtn {
    background-color: #2a2c3d;
    color: white;
    font-size: 16px;
    border: none;
    cursor: pointer;
  }
  
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown-content a:hover {background-color: #f1f1f1}
  
  .dropdown:hover .dropdown-content {
    display: block;
  }
  
  .dropdown:hover .dropbtn {
    background-color: #2a2c3d;
  }