body{
  font-family: Roboto;
}

.default-sidebar {
    background: #E5ECF8;
    position: fixed;
    height: 100%;
    top: 0;
    z-index: 999;
    transition: all 0.2s ease;
  }
  
  .default-sidebar .sidebar-separator {
    background: rgba(255, 255, 255, 0.05);
    height: 1px;
    width: 90%;
    margin: 1.07rem auto;
  }
  
  .default-sidebar > .side-navbar {
    min-width: 220px;
    max-width: 240px;
    color: #0047BA;
    z-index: 999;
    position: relative;
    height: 100%;
    padding: 75px 0;
    display: block;
  }
  
  .default-sidebar > .side-navbar a {
    color: inherit;
    position: relative;
    font-size: 1rem;
  }
  
  .default-sidebar > .side-navbar a[data-toggle="collapse"]::before {
    font-size: 0.85rem;
    content: '\f124';
    display: inline-block;
    transform: translateY(-50%);
    font-family: 'ionicons';
    position: absolute;
    top: 50%;
    right: 20px;
  }
  
  .default-sidebar > .side-navbar a[aria-expanded="true"] {
    background: #252946;
    margin: 0 10px 0 10px;
    border-radius: 4px 4px 0 0;
  }
  
  /* .default-sidebar > .side-navbar a[aria-expanded="true"] i {
    color: #e76c90;
  } */
  
  .default-sidebar > .side-navbar a[aria-expanded="true"]::before {
    content: '\f123';
  }
  
  .default-sidebar > .side-navbar a i {
    font-size: 1.4rem;
    margin-right: 10px;
    transition: none;
    vertical-align: -2px;
    color: #fff;
  }
  
  .default-sidebar > .side-navbar .sidebar-header {
    padding: 30px 15px;
  }
  
  .default-sidebar > .side-navbar span.heading {
    font-weight: 600;
    margin-left: 10px;
    color: #aea9c3;
    font-size: 0.85rem;
    text-transform: uppercase;
  }
  
  .default-sidebar > .side-navbar ul a {
    padding-top: 12px;
    padding-left: 21px;
    padding-bottom: 11px;
    text-decoration: none;
    display: block;
    font-weight: 500;
  }
  
  /* .default-sidebar > .side-navbar ul a:hover i {
    color: #e76c90;
  } */
  
  /* .default-sidebar > .side-navbar ul li.active i,
  .default-sidebar > .side-navbar li ul li a.active {
    background-color: #3381FF;
  } */
  
  .default-sidebar > .side-navbar ul ul {
    margin: 0 10px 0 10px;
  }
  
  .default-sidebar > .side-navbar ul li {
    background-color: #E5ECF8;
    height: 46px;
  }
  
  .default-sidebar > .side-navbar ul ul {
    background: #252946;
    border-radius: 0 0 4px 4px;
  }
  
  .default-sidebar > .side-navbar ul ul a {
    font-size: 0.85rem;
    padding-left: 40px;
    color: #aea9c3;
  }
  
  /* .default-sidebar > .side-navbar ul ul a:hover {
    color: #e76c90;
  } */
  
  .default-sidebar > .side-navbar ul ul a:focus {
    color: #aea9c3;
  }
  
  .default-sidebar > .side-navbar.shrinked ul.end-sidebar {
    padding-bottom: 0;
  }
  
  .default-sidebar > .side-navbar.shrinked .sidebar-gradient {
    display: none;
  }
  
  .default-sidebar > .side-navbar.shrinked {
    min-width: 90px;
    max-width: 90px;
    text-align: center;
  }
  
  .default-sidebar > .side-navbar.shrinked span.heading {
    font-size: 0.7rem;
    margin: 0;
  }
  
  .default-sidebar > .side-navbar.shrinked a {
    padding: 15px 10px;
    border: none;
    font-size: 0.7rem;
    transition: color 0.3s, background 0.3s;
  }
  
  .default-sidebar > .side-navbar.shrinked a[data-toggle="collapse"]::before {
    content: '\f123';
    transform: translateX(50%);
    position: absolute;
    top: auto;
    right: 50%;
    bottom: 0;
    left: auto;
  }
  
  .default-sidebar > .side-navbar.shrinked a[data-toggle="collapse"][aria-expanded="true"]::before {
    content: '\f126';
  }
  
  .default-sidebar > .side-navbar.shrinked a:hover {
    border: none;
  }
  
  .default-sidebar > .side-navbar.shrinked a {
    padding: 10px 0;
  }
  
  .default-sidebar > .side-navbar.shrinked a i {
    margin-right: 0;
    margin-bottom: 5px;
    display: block;
    font-size: 1.7rem;
    transition: color 0.3s;
  }
  
  .default-sidebar > .side-navbar.shrinked a span {
    display: none;
  }
  
  .default-sidebar > .side-navbar.shrinked .sidebar-header .title {
    display: none;
  }

  @media (max-width: 1200px) {
    .default-sidebar > .side-navbar,
    .compact-sidebar > .side-navbar {
      margin-left: -90px;
      min-width: 90px;
      max-width: 90px;
      text-align: center;
    }
  
    .default-sidebar > .side-navbar span.heading,
    .compact-sidebar > .side-navbar span.heading {
      margin: 0;
    }
  
    .default-sidebar > .side-navbar ul a,
    .compact-sidebar > .side-navbar ul a {
      padding: 10px 5px;
      border: none;
      font-size: 0.85rem;
    }
  
    .default-sidebar > .side-navbar ul a[data-toggle="collapse"]::before,
    .compact-sidebar > .side-navbar ul a[data-toggle="collapse"]::before {
      content: '\f123';
      transform: translateX(50%);
      position: absolute;
      top: auto;
      right: 50%;
      bottom: 0;
      left: auto;
    }
  
    .default-sidebar > .side-navbar ul a[data-toggle="collapse"][aria-expanded="true"]::before,
    .compact-sidebar > .side-navbar ul a[data-toggle="collapse"][aria-expanded="true"]::before {
      content: '\f126';
    }
  
    .default-sidebar > .side-navbar ul a:hover,
    .compact-sidebar > .side-navbar ul a:hover {
      border: none;
    }
  
    .default-sidebar > .side-navbar ul a i,
    .compact-sidebar > .side-navbar ul a i {
      margin-right: 0;
      margin-bottom: 5px;
      display: block;
      font-size: 1.6rem;
      transition: color 0.3s;
    }
  
    .default-sidebar > .side-navbar .sidebar-header .title
    .compact-sidebar > .side-navbar .sidebar-header .title {
      display: none;
    }
  
    .default-sidebar > .side-navbar.shrinked,
    .compact-sidebar > .side-navbar.shrinked {
      margin-left: 0;
    }
}

.box-scroll {
    overflow-x: auto!important;
  } 

  .list-unstyled {
    padding-left: 0;
    list-style: none;
  }

  .list-element > a:focus{
    background-color: #3381FF;
    color: #fff !important;
  }
  
  .list-element > a:hover{
    background-color: #C1D7FA;
  }
  
  .list-element > .active{
    background-color: #0047BA;
    color: #fff !important;
    pointer-events: none;
  }

/* span{
  text-align: left;
  font: normal normal bold 16px/48px Roboto;
  letter-spacing: 0px;
  color: #FFFFFF;
} */